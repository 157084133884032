import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
import { postAPI } from '../../utils/Api'
import AOS from 'aos'
import Loader from '../Components/Loader'
import Menus from '../Components/Menus'
// import Slider from '../Components/Slider'
import Section1 from '../Sections/Section1'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
// import Section6 from '../Sections/Section6'
// import Section7 from '../Sections/Section7'
// import Section8 from '../Sections/Section8'
import Footer from '../Components/Footer';
import ContactComponent from '../Components/Contact';
// import AppointmentComponent from '../Appointment/AppointmentComponent';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Index() {
    // const location = useLocation();
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;
    console.log(host);
    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Home"
        setTimeout(() => {
            setLoading(false)
        }, 3000)

        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, [host])
    return (
        <>

            {OrganizationData?.id ?
                <>
                    <Menus />
                    <br />
                    <br />
                    <br />
                    <main class="main">

                        <section id="pricing" class="pricing section light-background">


                            <div class="container section-title" data-aos="fade-up">
                                <h2>Pricing</h2>
                                <p>Register to our popular services.</p>
                            </div>

                            <div class="container" data-aos="fade-up" data-aos-delay="100">

                                <div class="row g-4 justify-content-center">


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card">
                                            <h3>Recruitment and Staffing</h3>
                                            {/* <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">0</span>
                                                <span class="period">/ month</span>
                                            </div> */}
                                            <p class="description">We will help you for Recruitment and Staffing.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-primary">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card">
                                            <h3>Manpower Consultation</h3>
                                            {/* <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">0</span>
                                                <span class="period">/ month</span>
                                            </div> */}
                                            <p class="description">We will help you for Manpower Consultation.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-primary">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Internships & Trainings</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">0</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Internships & Trainings.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Career Coaching</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">3,000</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Career Coaching.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Professional Resume Review</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">99</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Professional Resume Review.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Training and Development</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">3,000</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Training and Development.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: Ended
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Candidate Counselling</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">99</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Candidate Counselling.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>


                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                        <div class="pricing-card popular">
                                            <h3>Digital Marketing Course</h3>
                                            <div class="price">
                                                <span class="currency">₹</span>
                                                <span class="amount">5,999</span>
                                                {/* <span class="period">/ month</span> */}
                                            </div>
                                            <p class="description">We will help you for Digital Marketing Course.</p>

                                            <h4>Featured Included:</h4>
                                            <ul class="features-list">
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Duration: 1 Hour
                                                </li>
                                                {/* <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Excepteur sint occaecat
                                                </li>
                                                <li>
                                                    <i class="bi bi-check-circle-fill"></i>
                                                    Nemo enim ipsam voluptatem
                                                </li> */}
                                            </ul>

                                            <a href="#" class="btn btn-light">
                                                Book Now
                                                <i class="bi bi-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>



                                </div>

                            </div>
                        </section>
                    </main>
                    <Footer />
                </>
                :
                <Loader />}

        </>
    )
}