import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Contents({SectionId, OrgId}) {
    const [ContentsList, setContentsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchContentsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Contents/sectionWiseList', filter);
            setContentsList(data?.data || []); // Ensure ContentsList is always an array
        };

        fetchContentsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                ContentsList.length > 0 ? (
                    ContentsList.map((val, index) => (
                        <>
                        <h1 class="mb-4">
                            {val?.title}
                          {/* Maecenas Vitae <br />
                          Consectetur Led <br />
                          <span class="accent-text">Vestibulum Ante</span> */}
                        </h1>
  
                        <p class="mb-4 mb-md-5" dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
  
                        <div class="hero-buttons">
                          <a href="/Contact" class="btn btn-primary me-0 me-sm-2 mx-1">Get Started</a>
                          <a target='_blank' href="https://video.wixstatic.com/video/11062b_38e59a8142f94a6b91a2840fadc2a6ab/1080p/mp4/file.mp4" class="btn btn-link mt-2 mt-sm-0 glightbox">
                            <i class="bi bi-play-circle me-1"></i>
                            Play Video
                          </a>
                        </div>
                        </>
                    ))
                )
                : ''
            }
        </>
    );
}
