import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes,Redirect, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
// import { useParams } from 'react-router-dom'
// import logo from './logo.svg';
// // import './css/style.css';

// // import { store } from './app/store';
// import PrivateRoute from './routes/PrivateRoute';


// import { useDispatch } from 'react-redux';
// import { setupInterceptors } from './utils/Api';

// import E404 from './pages/Website/ClientPanel/E404/Index'
// import E500 from './pages/Website/ClientPanel/E500/Index'

// Client Panel

import Home from './Pages/Home/Index'
import About from './Pages/About/Index'
import Event from './Pages/Event/Index'
import Doctors from './Pages/Doctors/Index'
import Contact from './Pages/Contact/Index'
import Services from './Pages/Services/Index'
import FAQ from './Pages/FAQ/Index'
import Pricing from './Pages/Pricing/Index'
// import Appointment from './Pages/Appointment/Index'
import OtherPages from './Pages/OtherPages/Index'
import Page404 from './Pages/Page404/Index'

// import OTP from './pages/Website/ControlPanel/OTP/Index'
// import ForgotPassword from './pages/Website/ControlPanel/ForgotPassword/Index'
// import Login from './pages/Website/ControlPanel/Login/Index'
// import Register from './pages/Website/ControlPanel/Register/Index'

// // Control Panel
// import Dashboard from './pages/Website/ControlPanel/Dashboard/Index'

// import OrganizationSetup from './pages/Website/ControlPanel/OrganizationSetup/Index'
// import EmailTemplateSetup from './pages/Website/ControlPanel/EmailTemplateSetup/Index'
// import EmailSetup from './pages/Website/ControlPanel/EmailSetup/Index'
// import MenuSetup from './pages/Website/ControlPanel/MenuSetup/Index'
// import SliderSetup from './pages/Website/ControlPanel/SliderSetup/Index'
// import NoticeSetup from './pages/Website/ControlPanel/NoticeSetup/Index'
// import EventSetup from './pages/Website/ControlPanel/EventSetup/Index'
// import GallerySetup from './pages/Website/ControlPanel/GallerySetup/Index'
// import ManageFAQs from './pages/Website/ControlPanel/ManageFAQs/Index'
// import ManageLeads from './pages/Website/ControlPanel/ManageLeads/Index'
// import ManageQueries from './pages/Website/ControlPanel/ManageQueries/Index'
// import StaffSetup from './pages/Website/ControlPanel/StaffSetup/Index'
// import ContentSetup from './pages/Website/ControlPanel/ContentSetup/Index'
// import SectionSetup from './pages/Website/ControlPanel/SectionSetup/Index'
// import AdditionalPageSetup from './pages/Website/ControlPanel/AdditionalPageSetup/Index'
// import UITemplateSetup from './pages/Website/ControlPanel/UITemplateSetup/Index'
// import PaymentSetup from './pages/Website/ControlPanel/PaymentSetup/Index'
// import PaymentGatewaySetup from './pages/Website/ControlPanel/PaymentGatewaySetup/Index'
// import SMSSetup from './pages/Website/ControlPanel/SMSSetup/Index'
// import Store from './pages/Website/ControlPanel/Store/Index'






function App() {
	// const user = store.getState().auth.user; 

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/Home" element={<Home/>} />
        <Route exact path="/About" element={<About/>} />
        <Route exact path="/Event" element={<Event/>} />
        <Route exact path="/Team" element={<Doctors/>} />
        <Route exact path="/Contact" element={<Contact/>} />
        <Route exact path="/Services" element={<Services/>} />
        <Route exact path="/FAQs" element={<FAQ/>} />
        <Route exact path="/Pricing" element={<Pricing/>} />
        <Route exact path="/Pages/:id" element={<OtherPages/>} />
        <Route path="/" element={<Page404/>} />
        {/* <Route path="/Appointment" element={<Appointment/>} />
        <Route path="/Blogs" element={<Blogs/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
