import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import Content4 from './Content4';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Sections({ SectionId, OrgId }) {
    const [SectionsList, setSectionsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchSectionsData = async () => {
            const filter = { org_id: OrgId, id: SectionId };
            const data = await postAPI('/Sections/fetchSingle', filter);
            //   const data = await postAPI('/Sections/list', filter);
            setSectionsList(data?.data || []); // Ensure SectionsList is always an array
        };

        fetchSectionsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                // Check if SliderList is not empty, then render the mapped sliders
                SectionsList.length > 0 ? (
                    SectionsList.map((val, index) => (

                        <section id="services" class="services section light-background">


                            <div class="container section-title" data-aos="fade-up">
                                <h2 dangerouslySetInnerHTML={{ __html: val?.title }}></h2>
                                <p dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
                            </div>

                            <div class="container" data-aos="fade-up" data-aos-delay="100">

                                <div class="row g-4">
                                    <Content4 OrgId={OrgId} SectionId={SectionId} />
                                </div>

                            </div>

                        </section>
                    ))
                )
                    : ''
            }
        </>
    );
}
