import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
import { postAPI } from '../../utils/Api'
import AOS from 'aos'
// import AppointmentComponent from '../Appointment/AppointmentComponent';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function ContactComponent() {
    // const location = useLocation();
    const [FormData, setFormData] = useState({
        org_id: 2
    });

    const handleAppointmentSubmit = async () => {
        const data = await postAPI('/Queries/create', FormData);
        alert("Your query received to us.  We will get back to you shortly.")
    }
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;

    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Contact"
        setTimeout(() => {
            setLoading(false)
        }, 3000)

        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, [host])
    return (
        <>
<section id="contact" class="contact section light-background">


<div class="container section-title" data-aos="fade-up">
    <h2>Contact</h2>
    <p></p>
</div>

<div class="container" data-aos="fade-up" data-aos-delay="100">

    <div class="row g-4 g-lg-5">
        <div class="col-lg-5">
            <div class="info-box" data-aos="fade-up" data-aos-delay="200">
                <h3>Contact Info</h3>
                <p></p>

                <div class="info-item" data-aos="fade-up" data-aos-delay="300">
                    <div class="icon-box">
                        <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class="content">
                        <h4>Our Location</h4>
                        <p>{OrganizationData?.name}</p>
                        <p>{OrganizationData?.address}</p>
                    </div>
                </div>

                <div class="info-item" data-aos="fade-up" data-aos-delay="400">
                    <div class="icon-box">
                        <i class="bi bi-telephone"></i>
                    </div>
                    <div class="content">
                        <h4>Phone Number</h4>
                        <p>{OrganizationData?.contact_number}</p>
                    </div>
                </div>

                <div class="info-item" data-aos="fade-up" data-aos-delay="500">
                    <div class="icon-box">
                        <i class="bi bi-envelope"></i>
                    </div>
                    <div class="content">
                        <h4>Email Address</h4>
                        <p>{OrganizationData?.email}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-7">
            <div class="contact-form" data-aos="fade-up" data-aos-delay="300">
                <h3>Get In Touch</h3>
                <p>Feel free to connect with us. </p>

                <form action="forms/contact.php" method="post" class="php-email-form" data-aos="fade-up" data-aos-delay="200">
                    <div class="row gy-4">

                        <div class="col-md-6">
                            <input type="text" name="name" class="form-control" placeholder="Your Name"  onChange={e => setFormData({...FormData, "name": e.target.value})}required="" />
                        </div>

                        <div class="col-md-6 ">
                            <input type="email" class="form-control" name="email" placeholder="Your Email" required="" onChange={e => setFormData({...FormData, "email": e.target.value})} />
                        </div>

                        <div class="col-12">
                            <input type="text" class="form-control" name="subject" placeholder="Subject" required="" onChange={e => setFormData({...FormData, "subject": e.target.value})} />
                        </div>

                        <div class="col-12">
                            <textarea class="form-control" name="message" rows="6" placeholder="Message" required="" onChange={e => setFormData({...FormData, "details": e.target.value})}></textarea>
                        </div>

                        <div class="col-12 text-center">
                            <div class="loading">Loading</div>
                            <div class="error-message"></div>
                            <div class="sent-message">Your message has been sent. Thank you!</div>

                            <button type="button" onClick={handleAppointmentSubmit} class="btn">Send Message</button>
                        </div>

                    </div>
                </form>

            </div>
        </div>

    </div>

</div>

</section>
        </>
    )
}
