import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
import { postAPI } from '../../utils/Api'
import Menus from '../Components/Menus'
import Loader from '../Components/Loader'
import Footer from '../Components/Footer';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Index() {
    // const location = useLocation();
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;

    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    const [StaffList, setStaffList] = useState({})
    const [FormData, setFormData] = useState({
        "datepicker": new Date()
    })
    useEffect(() => {
        const fetchStaffList = async (org_id) => {
            const filter = { org_id: org_id };
            const data = await postAPI('/Staff/list', filter);
            setStaffList(data?.data || []); // Ensure ContentsList is always an array
        };
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
            console.log(data?.data[0])
            fetchStaffList(data?.data[0]['id']);
        };

        fetchOrganizationData();
        document.title = "Team"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [host])

    return (
        <>
            {OrganizationData?.id ?
                <>
                    <Menus />

                    <section className="blog section" id="blog">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title">
                                        <h2>Team</h2>
                                        <img src={Image} alt="#" />
                                        <p>Here is our team members.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    StaffList && StaffList?.length > 0 ?
                                        StaffList?.map((val, index) => (
                                            <div key={index} className="col-lg-4 col-md-6 col-12">
                                                <div className="single-news">
                                                    <div className="news-head">
                                                        <img src={`${API_URL}/${val?.image}`} alt="#" />
                                                    </div>
                                                    <div className="news-body">
                                                        <div className="news-content">
                                                            <h2 className="designation">{val?.designation}</h2>
                                                            <p className="name">{val?.name}</p>
                                                            <p className="qualification">{val?.qualification}</p>
                                                            <p className="email">{val?.email}</p>
                                                            <p className="contact_number">{val?.contact_number}</p>
                                                            {/* <h2><a href="blog-single.html" dangerouslySetInnerHTML={{ __html: val?.title }}></a></h2>
                                                    <p className="text" dangerouslySetInnerHTML={{ __html: val?.details }}></p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        : ''
                                }
                            </div>
                        </div>
                    </section>

                    <Footer OrganizationData={OrganizationData} />

                </>
                :
                <Loader />}

        </>
    )
}
