import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import Events from './Events';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Sections({ SectionId, OrgId }) {
  const [SectionsList, setSectionsList] = useState([]); // Initialize as an array

  useEffect(() => {
    const fetchSectionsData = async () => {
      const filter = { org_id: OrgId, id: SectionId };
      const data = await postAPI('/Sections/fetchSingle', filter);
      //   const data = await postAPI('/Sections/list', filter);
      setSectionsList(data?.data || []); // Ensure SectionsList is always an array
    };

    fetchSectionsData();
  }, [OrgId]); // Empty dependency array to run once on mount

  return (
    <>
      {
        // Check if SliderList is not empty, then render the mapped sliders
        SectionsList.length > 0 ? (
          SectionsList.map((val, index) => (
            <section key={index} className="blog section" id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title">
                              <h2 dangerouslySetInnerHTML={{ __html: val?.title }}></h2>
                              <img src={Image} alt="#" />
                              <p dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                      <Events OrgId={OrgId} />
                    </div>
                </div>
            </section>
          ))
        )
          : ''
      }
    </>
  );
}
