import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import Content1 from './Content1';
import Illustration1 from '../../assets/assets/img/illustration-1.webp'
import Avatar1 from '../../assets/assets/img/avatar-1.webp'
import Avatar2 from '../../assets/assets/img/avatar-2.webp'
import Avatar3 from '../../assets/assets/img/avatar-3.webp'
import Avatar4 from '../../assets/assets/img/avatar-4.webp'
import Avatar5 from '../../assets/assets/img/avatar-5.webp'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Sections({ SectionId, OrgId }) {
  const [SectionsList, setSectionsList] = useState([]); // Initialize as an array

  useEffect(() => {
    const fetchSectionsData = async () => {
      const filter = { org_id: OrgId, id: SectionId };
      const data = await postAPI('/Sections/fetchSingle', filter);
      //   const data = await postAPI('/Sections/list', filter);
      setSectionsList(data?.data || []); // Ensure SectionsList is always an array
    };

    fetchSectionsData();
  }, [OrgId]); // Empty dependency array to run once on mount

  return (
    <>

      {
        // Check if SliderList is not empty, then render the mapped sliders
        SectionsList.length > 0 ? (
          SectionsList.map((val, index) => (
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="hero-content" data-aos="fade-up" data-aos-delay="200">
                  <div class="company-badge mb-4">
                    <i class="bi bi-gear-fill me-2"></i>
                    {val?.title}
                  </div>
                  <Content1 OrgId={OrgId} SectionId={val?.id} />
                </div>
              </div>

              <div class="col-lg-6">
                <div class="hero-image" data-aos="zoom-out" data-aos-delay="300">
                  <img src={`${API_URL}/${val?.images}`} alt="Hero Image" class="img-fluid" />
                  <div class="customers-badge" dangerouslySetInnerHTML={{ __html: val?.contents }}></div>
                  {/* <div class="customers-badge">
                        <div class="customer-avatars">
                          <img src={Avatar1} alt="Customer 1" class="avatar" />
                          <img src={Avatar2} alt="Customer 2" class="avatar" />
                          <img src={Avatar3} alt="Customer 3" class="avatar" />
                          <img src={Avatar4} alt="Customer 4" class="avatar" />
                          <img src={Avatar5} alt="Customer 5" class="avatar" />
                          <span class="avatar more">12+</span>
                        </div>
                        <p class="mb-0 mt-2">12,000+ lorem ipsum dolor sit amet consectetur adipiscing elit</p>
                      </div> */}
                </div>
              </div>
            </div>


          ))
        )
          : ''
      }
    </>
  );
}
