import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Events({ SectionId, OrgId }) {
    const [EventsList, setEventsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchEventsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Event/list', filter);
            setEventsList(data?.data || []); // Ensure EventsList is always an array
        };

        fetchEventsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                EventsList.length > 0 ? (
                    EventsList.map((val, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-12">
                            <div className="single-news">
                                <div className="news-head">
                                    <img src={`${API_URL}/${val?.images}`} alt="#" />
                                </div>
                                <div className="news-body">
                                    <div className="news-content">
                                        <div className="date">{val?.formatted_date}</div>
                                        <h2><a href="blog-single.html" dangerouslySetInnerHTML={{ __html: val?.title }}></a></h2>
                                        <p className="text" dangerouslySetInnerHTML={{ __html: val?.details }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )
                    : ''
            }
        </>
    );
}
