import React, {useState, useEffect} from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Footer() {
    const [NewsletterData, setNewsletterData] = useState({})
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;
    const [OrganizationData, setOrganizationData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Home"
    }, [host])

    const handleNewsletterSubmit = async () => {
        const data = await postAPI('/Leads/create', NewsletterData);
    }
    return (
        <>
            
  <footer id="footer" class="footer">

<div class="container footer-top">
  <div class="row gy-4">
    <div class="col-lg-4 col-md-6 footer-about">
      <a href="index.html" class="logo d-flex align-items-center">
            <img src={`${API_URL}/${OrganizationData?.logo}`} style={{width: '200px'}} />
            <span class="sitename"></span>
      </a>
      <div class="footer-contact pt-3">
        <p>{OrganizationData?.about}</p>
        {/* <p>New York, NY 535022</p>
        <p class="mt-3"><strong>Phone:</strong> <span>+1 5589 55488 55</span></p>
        <p><strong>Email:</strong> <span>info@example.com</span></p> */}
      </div>
      <div class="social-links d-flex mt-4">
        <a href="https://api.whatsapp.com/message/5EALMDV2WIRKD1?autoload=1&app_absent=0"><i class="bi bi-whatsapp"></i></a>
        <a href="https://www.facebook.com/true.begins/"><i class="bi bi-facebook"></i></a>
        <a href="https://www.instagram.com/true_begins/p/CwkIpEXSMcN/"><i class="bi bi-instagram"></i></a>
        <a href="https://in.linkedin.com/company/true-begins-pvt-ltd"><i class="bi bi-linkedin"></i></a>
      </div>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Useful Links</h4>
      <ul>
        <li><a href="/Home">Home</a></li>
        <li><a href="/About">About us</a></li>
        <li><a href="/Services">Services</a></li>
        <li><a href="/FAQs">FAQs</a></li>
        {/* <li><a href="#">Terms of service</a></li>
        <li><a href="#">Privacy policy</a></li> */}
      </ul>
    </div>

    <div class="col-lg-2 col-md-3 footer-links">
      <h4>Our Services</h4>
      <ul>
        <li><a href="#">Recruitment and Staffing</a></li>
        <li><a href="#">Manpower Consultation</a></li>
        <li><a href="#">Internships & Trainings</a></li>
        <li><a href="#">Career Coaching</a></li>
        <li><a href="#">Professional Resume Review</a></li>
        <li><a href="#">Training and Development</a></li>
        <li><a href="#">Candidate Counselling</a></li>
        <li><a href="#">Digital Marketing Course</a></li>
      </ul>
    </div>

    <div class="col-lg-4 col-md-6 footer-links">
      <h4>Contact</h4>
      <ul>
        <li><a href="#">{OrganizationData?.name}</a></li>
        <li></li>
        <li><a href="#">{OrganizationData?.address}</a></li>
        <li><a href="#">{OrganizationData?.email}</a></li>
        <li><a href="#">{OrganizationData?.contact_number}</a></li>
        <li><a href="/Contact">Contact Us</a></li>
      </ul>
    </div>

  </div>
</div>

<div class="container copyright text-center mt-4">
  <p>© <span>Copyright</span> 2024 <strong class="px-1 sitename">Truebegins</strong> <span>All Rights Reserved</span></p>
  
  <div class="credits">
    Cared with <i class="bi bi-heart-fill fg-red"></i> by <a href="https://saasaisolutions.com/pages/contact.php">SaaS AI Solutions</a>
  </div>
</div>

</footer>
        </>
    )
}
