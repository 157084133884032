import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Contents({ SectionId, OrgId }) {
    const [ContentsList, setContentsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchContentsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Contents/sectionWiseList', filter);
            setContentsList(data?.data || []); // Ensure ContentsList is always an array
        };

        fetchContentsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                ContentsList.length > 0 ? (
                    ContentsList.map((val, index) => (
                        <div class="col-lg-3 col-md-6" key={index}>
                            <div class="stat-item">
                                <div class="stat-icon">
                                    <i class="bi bi-trophy"></i>
                                </div>
                                <div class="stat-content">
                                    <h4>{val?.title}</h4>
                                    <p class="mb-0" dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
                                </div>
                            </div>
                        </div>
                    ))
                )
                    : ''
            }
        </>
    );
}
