import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
// import Image from '../../assets/img/section-img.png'
// import ContactImage from '../../assets/img/contact-img.png'
import { postAPI } from '../../utils/Api'
import Menus from '../Components/Menus'
import Loader from '../Components/Loader'
import Footer from '../Components/Footer';
import ContactComponent from '../Components/Contact';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Index() {
    // const location = useLocation();
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;

    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    const [FormData, setFormData] = useState({
        "datepicker": new Date()
    })
    const [NewsletterData, setNewsletterData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Contact"
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [host])

    const handleNewsletterSubmit = async () => {
        const data = await postAPI('/Leads/create', NewsletterData);
    }

    const handleAppointmentSubmit = async () => {
        const data = await postAPI('/Leads/create', FormData);
    }
    return (
        <>
            {OrganizationData?.id ?
                <>
                    <Menus />
                    <br />
                    <br />
                    <br />

                    <ContactComponent OrgId={OrganizationData?.id} />

                    <Footer />

                </>
                :
                <Loader />}
        </>
    )
}
