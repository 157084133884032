import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import Content3 from './Content3';
// import Image from '../../assets/img/section-img.png'
import Avatar1 from "../../assets/assets/img/avatar-1.webp"
import About5 from "../../assets/assets/img/about-5.webp"
import About2 from "../../assets/assets/img/about-2.webp"
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Sections({SectionId, OrgId}) {
  const [SectionsList, setSectionsList] = useState([]); // Initialize as an array

  useEffect(() => {
    const fetchSectionsData = async () => {
      const filter = { org_id: OrgId, id: SectionId };
      const data = await postAPI('/Sections/fetchSingle', filter);
    //   const data = await postAPI('/Sections/list', filter);
      setSectionsList(data?.data || []); // Ensure SectionsList is always an array
    };

    fetchSectionsData();
  }, [OrgId]); // Empty dependency array to run once on mount

  return (
    <>
        {
          // Check if SliderList is not empty, then render the mapped sliders
          SectionsList.length > 0 ? (
            SectionsList.map((val, index) => (
              
              <section id="about" class="about section">

              <div class="container" data-aos="fade-up" data-aos-delay="100">

                  <div class="row gy-4 align-items-center justify-content-between">

                      <div class="col-xl-5" data-aos="fade-up" data-aos-delay="200">
                          <span class="about-meta">{val?.title}</span>
                          <h2 class="about-title" dangerouslySetInnerHTML={{ __html: val?.contents }}></h2>
                          <Content3 OrgId={OrgId} SectionId={SectionId} />
                          
                      </div>

                      <div class="col-xl-6" data-aos="fade-up" data-aos-delay="300">
                          <div class="image-wrapper">
                              <div class="images position-relative" data-aos="zoom-out" data-aos-delay="400">
                                  <img src={About5} alt="Business Meeting" class="img-fluid main-image rounded-4" />
                                  {/* <img src={About2} alt="Team Discussion" class="img-fluid small-image rounded-4" /> */}
                              </div>
                              <div class="experience-badge floating">
                                  <h3>15+ <span>Years</span></h3>
                                  <p>Of experience in business service</p>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>

          </section>
                // <div key={index} id="fun-facts" className="fun-facts section overlay">
                //     <div className="container">
                //         <div className="row">
                //             
                //         </div>
                //     </div>
                // </div>
            ))
          )
          : ''
        }
    </>
  );
}
