import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Contents({ SectionId, OrgId }) {
    const [ContentsList, setContentsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchContentsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Contents/sectionWiseList', filter);
            setContentsList(data?.data || []); // Ensure ContentsList is always an array
        };

        fetchContentsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                ContentsList.length > 0 ? (
                    ContentsList.map((val, index) => (
                        <div key={index} class={!index ? "faq-item faq-active" : "faq-item" }>
                            <h3 dangerouslySetInnerHTML={{ __html: val?.title }}></h3>
                            <div class="faq-content">
                                <p dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
                            </div>
                            <i class="faq-toggle bi bi-chevron-right"></i>
                        </div>
                    ))
                )
                    : ''
            }
        </>
    );
}
