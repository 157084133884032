import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import Content2 from './Content2';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Sections({ SectionId, OrgId }) {
  const [SectionsList, setSectionsList] = useState([]); // Initialize as an array

  useEffect(() => {
    const fetchSectionsData = async () => {
      const filter = { org_id: OrgId, id: SectionId };
      const data = await postAPI('/Sections/fetchSingle', filter);
      //   const data = await postAPI('/Sections/list', filter);
      setSectionsList(data?.data || []); // Ensure SectionsList is always an array
    };

    fetchSectionsData();
  }, [OrgId]); // Empty dependency array to run once on mount

  return (
    <>
      {
        // Check if SliderList is not empty, then render the mapped sliders
        SectionsList.length > 0 ? (
          SectionsList.map((val, index) => (
            <div class="row stats-row gy-4 mt-5" data-aos="fade-up" data-aos-delay="500">
            <Content2 OrgId={OrgId} SectionId={val?.id} />
            </div>
          ))
        )
          : ''
      }
    </>
  );
}
