import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Contents({ SectionId, OrgId }) {
    const [ContentsList, setContentsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchContentsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Contents/sectionWiseList', filter);
            setContentsList(data?.data || []); // Ensure ContentsList is always an array
        };

        fetchContentsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                ContentsList.length > 0 ? (
                    ContentsList.map((val, index) => (
                        <div key={index} class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                            <div class="service-card d-flex">
                                <div class="icon flex-shrink-0">
                                    <i class="bi bi-activity"></i>
                                </div>
                                <div>
                                    <h3 dangerouslySetInnerHTML={{ __html: val?.title }}></h3>
                                    <p dangerouslySetInnerHTML={{ __html: val?.contents }}></p>
                                    <a href="/Services" class="read-more">Read More <i class="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    ))
                )
                    : ''
            }
        </>
    );
}
