import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
import { FaMapMarkerAlt } from "react-icons/fa";
import Logo from '../../assets/logo.png'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Menus() {
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;
    const [OrganizationData, setOrganizationData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Home"
    }, [host])
    return (
        <header id="header" class="header d-flex align-items-center fixed-top">
            <div class="header-container container-fluid container-xl position-relative d-flex align-items-center justify-content-between">

                <a href="index.html" class="logo d-flex align-items-center me-auto me-xl-0">
                    <img src={`${API_URL}/${OrganizationData?.logo}`} alt="" />
                        {/* <h1 class="sitename">iLanding</h1> */}
                </a>

                <nav id="navmenu" class="navmenu">
                    <ul>
                        <li><a href="/" class="active">Home</a></li>
                        <li><a href="/About">About</a></li>
                        {/* <li><a href="#features">Features</a></li> */}
                        <li><a href="/Services">Services</a></li>
                        <li><a href="/Pricing">Pricing</a></li>
                        <li><a href="/Team">Team</a></li>
                        <li><a href="/Event">Event</a></li>
                        <li><a href="/FAQs">FAQs</a></li>
                        {/* <li class="dropdown"><a href="#"><span>Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                            <ul>
                                <li><a href="#">Dropdown 1</a></li>
                                <li class="dropdown"><a href="#"><span>Deep Dropdown</span> <i class="bi bi-chevron-down toggle-dropdown"></i></a>
                                    <ul>
                                        <li><a href="#">Deep Dropdown 1</a></li>
                                        <li><a href="#">Deep Dropdown 2</a></li>
                                        <li><a href="#">Deep Dropdown 3</a></li>
                                        <li><a href="#">Deep Dropdown 4</a></li>
                                        <li><a href="#">Deep Dropdown 5</a></li>
                                    </ul>
                                </li>
                                <li><a href="#">Dropdown 2</a></li>
                                <li><a href="#">Dropdown 3</a></li>
                                <li><a href="#">Dropdown 4</a></li>
                            </ul>
                        </li> */}
                        <li><a href="/Pages/our-story">Our Story</a></li>
                    </ul>
                    <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
                </nav>

                <a class="btn-getstarted" href="/Contact">Contact Now</a>

            </div>
        </header>
        // <header className="header" >
        //     <div className="topbar">
        //         <div className="container">
        //             <div className="row">
        //                 <div className="col-lg-6 col-md-5 col-12">
        //                     <ul className="top-link">
        //                         <li><FaMapMarkerAlt /> {OrganizationData?.address}</li>
        //                     </ul>
        //                 </div>
        //                 <div className="col-lg-6 col-md-7 col-12">
        //                     <ul className="top-contact">
        //                         <li><i className="fa fa-phone"></i>{OrganizationData?.contact_number}</li>
        //                         <li><i className="fa fa-envelope"></i><a href={`mailto:${OrganizationData?.email}`}>{OrganizationData?.email}</a></li>
        //                     </ul>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="header-inner">
        //         <div className="container">
        //             <div className="inner">
        //                 <div className="row">
        //                     <div className="col-lg-3 col-md-3 col-12">
        //                         <div className="logo">
        //                             <a href="/"><img src={`${API_URL}/${OrganizationData?.logo}`} alt="#" /></a>
        //                         </div>
        //                         <div className="mobile-nav"></div>
        //                     </div>
        //                     <div className="col-lg-7 col-md-9 col-12">
        //                         <div className="main-menu">
        //                             <nav className="navigation">
        //                                 <ul className="nav menu">
        //                                     <li><a href="/">Home </a></li>
        //                                     <li><a href="/About">About </a></li>
        //                                     <li><a href="/Doctors">Doctors </a></li>
        //                                     {/* <li><a href="/Blogs">Blogs </a></li> */}
        //                                     <li><a href="/Contact">Contact Us</a></li>
        //                                 </ul>
        //                             </nav>
        //                         </div>
        //                     </div>
        //                     <div className="col-lg-2 col-12">
        //                         <div className="get-quote">
        //                             <a href="/Appointment" className="btn">Book Appointment</a>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </header>
    )
}
