import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
import { postAPI } from '../../utils/Api'
import AOS from 'aos'
import Loader from '../Components/Loader'
import Menus from '../Components/Menus'
import Footer from '../Components/Footer';
// import AppointmentComponent from '../Appointment/AppointmentComponent';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Index() {
    const { id } = useParams();
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;
    console.log(host);
    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    const [PageData, setPageData] = useState({})
    useEffect(() => {
        const fetchPages = async (org_id) => {
            const filter = { org_id: org_id };
            const data = await postAPI('/AdditionalPage/list', filter);
            setPageData(data?.data || []); // Ensure ContentsList is always an array
        };
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
            fetchPages(data?.data[0]['id']);
        };

        fetchOrganizationData();
        document.title = "Home"
        setTimeout(() => {
            setLoading(false)
        }, 3000)

        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, [host])
    return (
        <>
            {OrganizationData?.id ?
                <>
                    <Menus />
                    <br />
                    <br />
                    <br />
                    <main class="main">
                        {/* // <div>{val?.title}</div> */}
                        <section id="service-details" class="service-details section">

                            <div class="container">

                                <div class="row gy-5">

                                    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="100">

                                        <div class="service-box">
                                            <h4>Pages List</h4>
                                            <div class="services-list">


                                                {PageData?.length > 0 ?
                                                    PageData?.map((val, index) => (
                                                        <a href={`/Pages/${val?.menu_link}`} class={`${val?.menu_link == id ? "active" : ""}`}><i class="bi bi-arrow-right-circle"></i><span>{val?.title}</span></a>
                                                    ))
                                                    : ''}
                                            </div>
                                        </div>

                                        {/* <div class="service-box">
                                                    <h4>Download Catalog</h4>
                                                    <div class="download-catalog">
                                                        <a href="#"><i class="bi bi-filetype-pdf"></i><span>Catalog PDF</span></a>
                                                        <a href="#"><i class="bi bi-file-earmark-word"></i><span>Catalog DOC</span></a>
                                                    </div>
                                                </div> */}

                                        <div class="help-box d-flex flex-column justify-content-center align-items-center">
                                            <i class="bi bi-headset help-icon"></i>
                                            <h4>Have a Question?</h4>
                                            <p class="d-flex align-items-center mt-2 mb-0"><i class="bi bi-telephone me-2"></i> <span>{OrganizationData?.contact_number}</span></p>
                                            <p class="d-flex align-items-center mt-1 mb-0"><i class="bi bi-envelope me-2"></i> <a href="mailto:contact@example.com">{OrganizationData?.email}</a></p>
                                        </div>

                                    </div>

                                    {PageData?.length > 0 ?
                                        PageData?.map((val, index) => (
                                            val?.menu_link == id ?
                                                <div class="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
                                                    <div className='img-fluid '>
                                                        <img src={`${API_URL}/${val?.banner_image}`} alt="" class="services-img" />
                                                        <h3>{val?.title}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: val?.content }}></div>
                                                    </div>
                                                </div>
                                                : ''
                                        ))
                                        : '-'}

                                </div>

                            </div>

                        </section>
                    </main>
                    <Footer />
                </>
                :
                <Loader />}

        </>
    )
}