import React, { useState, useEffect } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useLocation } from 'react-router-dom'
import { postAPI } from '../../utils/Api'
import AOS from 'aos'
import Loader from '../Components/Loader'
import Menus from '../Components/Menus'
// import Slider from '../Components/Slider'
import Section1 from '../Sections/Section1'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
// import Section6 from '../Sections/Section6'
// import Section7 from '../Sections/Section7'
// import Section8 from '../Sections/Section8'
import Footer from '../Components/Footer';
import ContactComponent from '../Components/Contact';
// import AppointmentComponent from '../Appointment/AppointmentComponent';
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Index() {
    // const location = useLocation();
    const host = process.env.REACT_APP_ACCEPT_STATIC_URL === "ACCEPT" ? process.env.REACT_APP_ACCEPT_APP_URL : window?.location?.host;
    console.log(host);
    const [Loading, setLoading] = useState(true)
    const [OrganizationData, setOrganizationData] = useState({})
    useEffect(() => {
        const fetchOrganizationData = async () => {
            const filter = { requested_domain: host };
            const data = await postAPI('/Organization/fetchSingle', filter);
            setOrganizationData(data?.data[0] || []); // Ensure ContentsList is always an array
        };

        fetchOrganizationData();
        document.title = "Home"
        setTimeout(() => {
            setLoading(false)
        }, 3000)

        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        });
    }, [host])
    return (
        <>

            {OrganizationData?.id ?
                <>
                    <Menus />

                    <main class="main">

                        <section id="hero" class="hero section">
                            <div class="container" data-aos="fade-up" data-aos-delay="100">
                                <Section1 OrgId={OrganizationData?.id} SectionId={13} />
                                <Section2 OrgId={OrganizationData?.id} SectionId={14} />


                            </div>
                        </section>
                        <Section3 OrgId={OrganizationData?.id} SectionId={15} />

                        {/* Services */}
                        <Section4 OrgId={OrganizationData?.id} SectionId={21} />

                        <Section5 OrgId={OrganizationData?.id} SectionId={22} />

                        <ContactComponent />


                        {/* <section id="features" class="features section">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Features</h2>
                        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
                    </div>

                    <div class="container">

                        <div class="d-flex justify-content-center">

                            <ul class="nav nav-tabs" data-aos="fade-up" data-aos-delay="100">

                                <li class="nav-item">
                                    <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#features-tab-1">
                                        <h4>Modisit</h4>
                                    </a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-2">
                                        <h4>Praesenti</h4>
                                    </a>

                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3">
                                        <h4>Explica</h4>
                                    </a>
                                </li>

                            </ul>

                        </div>

                        <div class="tab-content" data-aos="fade-up" data-aos-delay="200">

                            <div class="tab-pane fade active show" id="features-tab-1">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Voluptatem dignissimos provident</h3>
                                        <p class="fst-italic">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua.
                                        </p>
                                        <ul>
                                            <li><i class="bi bi-check2-all"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</span></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/features-illustration-1.webp" alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="features-tab-2">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Neque exercitationem debitis</h3>
                                        <p class="fst-italic">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua.
                                        </p>
                                        <ul>
                                            <li><i class="bi bi-check2-all"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</span></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/features-illustration-2.webp" alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="features-tab-3">
                                <div class="row">
                                    <div class="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Voluptatibus commodi accusamu</h3>
                                        <ul>
                                            <li><i class="bi bi-check2-all"></i> <span>Ullamco laboris nisi ut aliquip ex ea commodo consequat.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Duis aute irure dolor in reprehenderit in voluptate velit.</span></li>
                                            <li><i class="bi bi-check2-all"></i> <span>Provident mollitia neque rerum asperiores dolores quos qui a. Ipsum neque dolor voluptate nisi sed.</span></li>
                                        </ul>
                                        <p class="fst-italic">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                            magna aliqua.
                                        </p>
                                    </div>
                                    <div class="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/features-illustration-3.webp" alt="" class="img-fluid" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>


                <section id="features-cards" class="features-cards section">

                    <div class="container">

                        <div class="row gy-4">

                            <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                                <div class="feature-box orange">
                                    <i class="bi bi-award"></i>
                                    <h4>Corporis voluptates</h4>
                                    <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                                <div class="feature-box blue">
                                    <i class="bi bi-patch-check"></i>
                                    <h4>Explicabo consectetur</h4>
                                    <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                                <div class="feature-box green">
                                    <i class="bi bi-sunrise"></i>
                                    <h4>Ullamco laboris</h4>
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                                </div>
                            </div>

                            <div class="col-xl-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                                <div class="feature-box red">
                                    <i class="bi bi-shield-check"></i>
                                    <h4>Labore consequatur</h4>
                                    <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>


                <section id="features-2" class="features-2 section">

                    <div class="container" data-aos="fade-up" data-aos-delay="100">

                        <div class="row align-items-center">

                            <div class="col-lg-4">

                                <div class="feature-item text-end mb-5" data-aos="fade-right" data-aos-delay="200">
                                    <div class="d-flex align-items-center justify-content-end gap-4">
                                        <div class="feature-content">
                                            <h3>Use On Any Device</h3>
                                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; In ac dui quis mi consectetuer lacinia.</p>
                                        </div>
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-display"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="feature-item text-end mb-5" data-aos="fade-right" data-aos-delay="300">
                                    <div class="d-flex align-items-center justify-content-end gap-4">
                                        <div class="feature-content">
                                            <h3>Feather Icons</h3>
                                            <p>Phasellus ullamcorper ipsum rutrum nunc nunc nonummy metus vestibulum volutpat sapien arcu sed augue aliquam erat volutpat.</p>
                                        </div>
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-feather"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="feature-item text-end" data-aos="fade-right" data-aos-delay="400">
                                    <div class="d-flex align-items-center justify-content-end gap-4">
                                        <div class="feature-content">
                                            <h3>Retina Ready</h3>
                                            <p>Aenean tellus metus bibendum sed posuere ac mattis non nunc vestibulum fringilla purus sit amet fermentum aenean commodo.</p>
                                        </div>
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-eye"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                                <div class="phone-mockup text-center">
                                    <img src="assets/img/phone-app-screen.webp" alt="Phone Mockup" class="img-fluid" />
                                </div>
                            </div>

                            <div class="col-lg-4">

                                <div class="feature-item mb-5" data-aos="fade-left" data-aos-delay="200">
                                    <div class="d-flex align-items-center gap-4">
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-code-square"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>W3c Valid Code</h3>
                                            <p>Donec vitae sapien ut libero venenatis faucibus nullam quis ante etiam sit amet orci eget eros faucibus tincidunt.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="feature-item mb-5" data-aos="fade-left" data-aos-delay="300">
                                    <div class="d-flex align-items-center gap-4">
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-phone"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>Fully Responsive</h3>
                                            <p>Maecenas tempus tellus eget condimentum rhoncus sem quam semper libero sit amet adipiscing sem neque sed ipsum.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="feature-item" data-aos="fade-left" data-aos-delay="400">
                                    <div class="d-flex align-items-center gap-4">
                                        <div class="feature-icon flex-shrink-0">
                                            <i class="bi bi-browser-chrome"></i>
                                        </div>
                                        <div class="feature-content">
                                            <h3>Browser Compatibility</h3>
                                            <p>Nullam dictum felis eu pede mollis pretium integer tincidunt cras dapibus vivamus elementum semper nisi aenean vulputate.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </section>


                <section id="call-to-action" class="call-to-action section">

                    <div class="container" data-aos="fade-up" data-aos-delay="100">

                        <div class="row content justify-content-center align-items-center position-relative">
                            <div class="col-lg-8 mx-auto text-center">
                                <h2 class="display-4 mb-4">Maecenas tempus tellus eget condimentum</h2>
                                <p class="mb-4">Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel</p>
                                <a href="#" class="btn btn-cta">Call To Action</a>
                            </div>


                            <div class="shape shape-1">
                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47.1,-57.1C59.9,-45.6,68.5,-28.9,71.4,-10.9C74.2,7.1,71.3,26.3,61.5,41.1C51.7,55.9,35,66.2,16.9,69.2C-1.3,72.2,-21,67.8,-36.9,57.9C-52.8,48,-64.9,32.6,-69.1,15.1C-73.3,-2.4,-69.5,-22,-59.4,-37.1C-49.3,-52.2,-32.8,-62.9,-15.7,-64.9C1.5,-67,34.3,-68.5,47.1,-57.1Z" transform="translate(100 100)"></path>
                                </svg>
                            </div>

                            <div class="shape shape-2">
                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M41.3,-49.1C54.4,-39.3,66.6,-27.2,71.1,-12.1C75.6,3,72.4,20.9,63.3,34.4C54.2,47.9,39.2,56.9,23.2,62.3C7.1,67.7,-10,69.4,-24.8,64.1C-39.7,58.8,-52.3,46.5,-60.1,31.5C-67.9,16.4,-70.9,-1.4,-66.3,-16.6C-61.8,-31.8,-49.7,-44.3,-36.3,-54C-22.9,-63.7,-8.2,-70.6,3.6,-75.1C15.4,-79.6,28.2,-58.9,41.3,-49.1Z" transform="translate(100 100)"></path>
                                </svg>
                            </div>


                            <div class="dots dots-1">
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <pattern id="dot-pattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                        <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                                    </pattern>
                                    <rect width="100" height="100" fill="url(#dot-pattern)"></rect>
                                </svg>
                            </div>

                            <div class="dots dots-2">
                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <pattern id="dot-pattern-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                        <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
                                    </pattern>
                                    <rect width="100" height="100" fill="url(#dot-pattern-2)"></rect>
                                </svg>
                            </div>

                            <div class="shape shape-3">
                                <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M43.3,-57.1C57.4,-46.5,71.1,-32.6,75.3,-16.2C79.5,0.2,74.2,19.1,65.1,35.3C56,51.5,43.1,65,27.4,71.7C11.7,78.4,-6.8,78.3,-23.9,72.4C-41,66.5,-56.7,54.8,-65.4,39.2C-74.1,23.6,-75.8,4,-71.7,-13.2C-67.6,-30.4,-57.7,-45.2,-44.3,-56.1C-30.9,-67,-15.5,-74,0.7,-74.9C16.8,-75.8,33.7,-70.7,43.3,-57.1Z" transform="translate(100 100)"></path>
                                </svg>
                            </div>
                        </div>

                    </div>

                </section>


                <section id="clients" class="clients section">

                    <div class="container" data-aos="fade-up" data-aos-delay="100">

                        <div class="swiper init-swiper">
                            <div class="swiper-wrapper align-items-center">
                                <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt="" /></div>
                                <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt="" /></div>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>

                    </div>

                </section>


                <section id="testimonials" class="testimonials section light-background">


                    <div class="container section-title" data-aos="fade-up">
                        <h2>Testimonials</h2>
                        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
                    </div>

                    <div class="container">

                        <div class="row g-5">

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                                <div class="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="" />
                                    <h3>Saul Goodman</h3>
                                    <h4>Ceo &amp; Founder</h4>
                                    <div class="stars">
                                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                        <i class="bi bi-quote quote-icon-left"></i>
                                        <span>Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.</span>
                                        <i class="bi bi-quote quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                                <div class="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="" />
                                    <h3>Sara Wilsson</h3>
                                    <h4>Designer</h4>
                                    <div class="stars">
                                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                        <i class="bi bi-quote quote-icon-left"></i>
                                        <span>Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.</span>
                                        <i class="bi bi-quote quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                <div class="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="" />
                                    <h3>Jena Karlis</h3>
                                    <h4>Store Owner</h4>
                                    <div class="stars">
                                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                        <i class="bi bi-quote quote-icon-left"></i>
                                        <span>Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.</span>
                                        <i class="bi bi-quote quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>

                            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="400">
                                <div class="testimonial-item">
                                    <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="" />
                                    <h3>Matt Brandon</h3>
                                    <h4>Freelancer</h4>
                                    <div class="stars">
                                        <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                        <i class="bi bi-quote quote-icon-left"></i>
                                        <span>Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.</span>
                                        <i class="bi bi-quote quote-icon-right"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="stats" class="stats section">
                    <div class="container" data-aos="fade-up" data-aos-delay="100">
                        <div class="row gy-4">
                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
                                    <p>Clients</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                                    <p>Projects</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" class="purecounter"></span>
                                    <p>Hours Of Support</p>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-6">
                                <div class="stats-item text-center w-100 h-100">
                                    <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" class="purecounter"></span>
                                    <p>Workers</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>


                 */}




                        {/* <section id="call-to-action-2" class="call-to-action-2 section dark-background">
                    <div class="container">
                        <div class="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                            <div class="col-xl-10">
                                <div class="text-center">
                                    <h3>Call To Action</h3>
                                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                    <a class="cta-btn" href="#">Call To Action</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                    </main>
                    <Footer />
                </>
                :
                <Loader />}

        </>
    )
}
