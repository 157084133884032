import React, { useState, useEffect } from 'react'
import { postAPI } from '../../utils/Api'
const API_URL = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_LIVE;

export default function Contents({ SectionId, OrgId }) {
    const [ContentsList, setContentsList] = useState([]); // Initialize as an array

    useEffect(() => {
        const fetchContentsData = async () => {
            const filter = { org_id: OrgId, section_id: SectionId };
            const data = await postAPI('/Contents/sectionWiseList', filter);
            setContentsList(data?.data || []); // Ensure ContentsList is always an array
        };

        fetchContentsData();
    }, [OrgId]); // Empty dependency array to run once on mount

    return (
        <>
            {
                ContentsList.length > 0 ? (
                    ContentsList.map((val, index) => (
                        <>
                            <p class="about-description">{val?.title}</p>
                            <div dangerouslySetInnerHTML={{__html: val?.contents}}></div>
                        </>
                        // <div className="col-lg-3 col-md-6 col-12" key={index}>
                        //     <div className="single-fun">
                        //         <i className="icofont icofont-home"></i>
                        //         <div className="content">
                        //             <span className="counter" dangerouslySetInnerHTML={{__html: val?.title}}></span>
                        //             <p></p>
                        //         </div>
                        //     </div>
                        // </div>
                    ))
                )
                    : ''
            }
        </>
    );
}
